import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

const NotFound = () => import('../views/NotFound/NotFound.vue')
const LoginView = () => import('../views/Login.vue')
const AdminWelcomeView = () => import('../views/Admin/AdminWelcome.vue')
const AdminPageView = () => import('../views/Admin/AdminPage.vue')
const LoginLog = () => import('../views/Admin/SystemLog/LoginLogView.vue')
const OperationLog = () => import('../views/Admin/SystemLog/OperationLogView.vue')
const UserCtrl = () => import('../views/Admin/PlatformCtrl/UserCtrl.vue')
const RoleCtrl = () => import('../views/Admin/PlatformCtrl/RoleCtrl.vue')
const MenuCtrl = () => import('../views/Admin/PlatformCtrl/MenuCtrl.vue')
const AheadApplication = () => import('../views/Application/App/EmpPage.vue')
const AppDeepSeekPage = () => import('../views/Application/App/DeepSeekPage.vue')
const qwqApp = () => import('../views/Application/App/QwqPage.vue')
const StudentAppModule = () => import('../views/Module/StudentAppModule.vue')
const OAModule = () => import('../views/Module/OAModule.vue')
const Dify = () => import('../views/Module/Dify.vue')
const FileUploadView = () => import('../views/Admin/DataMage/FileManager.vue')
const TestTemplate = () => import('../views/Admin/TestTemplateMage/testTemplateTest.vue')
const TestTemplateGroup = () => import('../views/Admin/TestTemplateMage/testTemplateGroupMge.vue')
const TestTemplateCollectionMge = () => import('../views/Admin/TestTemplateMage/testTemplateCollectionMge.vue')
const WebEmpPage = () => import('../views/Application/Web/EmpPage.vue')
const WebDeepSeekPage = () => import('../views/Application/Web/DeepSeekPage.vue')
const qwqWeb = () => import('../views/Application/Web/QwqPage.vue')
const Default = () => import('../views/Default.vue')
const RequestLog = () => import('../views/Admin/UsageMage/RequestLogView.vue')
const FeedBackLog = () => import('../views/Admin/UsageMage/FeedBackLogView.vue')
const RequestExceptionLog = () => import('../views/Admin/UsageMage/RequestExceptionLogView.vue')
const BillingStatistics = () => import('../views/Admin/UsageMage/BillingStatistics.vue')
const ModelChargingMge = () => import('../views/Admin/UsageMage/ModelChargingMge.vue')
const AudioToVideo = () => import('../views/Application/App/wanxiang/AudioToVideoPage.vue')
const AudioToVideoWeb = () => import('../views/Application/Web/AudioToVideoPage.vue')
const WanxiangNavigation = () => import('../views/Application/App/wanxiang/WanxiangNavigation.vue')
const TestExamine = () => import('../views/Admin/TestTemplateMage/testExamine.vue')
const TextToImage = () => import('../views/Application/App/wanxiang/TextToImage.vue')
const ImageToVideo = () => import('../views/Application/App/wanxiang/ImageToVideoPage.vue')

Vue.use(VueRouter)

// 非权限路由
const routes = [{
  path: '/admin/login',
  name: 'login',
  component: LoginView,
},
{
  path: '/aheadapp',
  name: 'aheadapp',
  component: AheadApplication,
},
{
  path: '/aheadweb',
  name: 'webemppage',
  component: WebEmpPage,
},
{
  path: '/default',
  name: 'default',
  component: Default
},
{
  path: '/deepseekweb',
  name: 'deepseekweb',
  component: WebDeepSeekPage
},
{
  path: '/deepseekapp',
  name: 'deepseekapp',
  component: AppDeepSeekPage
},
{
  path: '/AudioToVideo',
  name: 'audioToVideo',
  component: AudioToVideo
},
{
  path: '/audioToVideoWeb',
  name: 'audioToVideoWeb',
  component: AudioToVideoWeb
},
{
  path: '/wanxiangNavigation',
  name: 'WanxiangNavigation',
  component: WanxiangNavigation
},
{
  path: '/textToImage',
  name: 'TextToImage',
  component: TextToImage
},
{
  path: '/imageToVideo',
  name: 'imageToVideo',
  component: ImageToVideo
},{
  path: '/qwqWeb',
  name: 'qwqWeb',
  component: qwqWeb
},{
  path: '/qwqApp',
  name: 'qwqApp',
  component: qwqApp
}
]

// 权限路由
const rightRoutes = [{
  path: '/adminpage',
  name: 'adminpage',
  redirect: '/adminWelcome',
  component: AdminPageView,
  children: [{
    path: '/adminWelcome',
    component: AdminWelcomeView,
  },
  {
    path: '/userctrl',
    component: UserCtrl,
  },
  {
    path: '/roleCtrl',
    component: RoleCtrl,
  },
  {
    path: '/menuctrl',
    component: MenuCtrl,
  },
  {
    path: '/loginLog',
    component: LoginLog
  },
  {
    path: '/operationLog',
    component: OperationLog
  },
  {
    path: '/dify',
    component: Dify
  },
  {
    path: '/requestLog',
    component: RequestLog
  },
  {
    path: '/oamodule',
    component: OAModule
  },
  {
    path: '/studentappmodule',
    component: StudentAppModule
  },
  {
    path: '/fileUpload',
    component: FileUploadView
  }, {
    path: '/testTemplate',
    component: TestTemplate
  }, {
    path: '/testTemplateGroup',
    component: TestTemplateGroup
  }, {
    path: '/testTemplateMge',
    component: TestTemplateCollectionMge
  },
  {
    path: '/feedBackLog',
    component: FeedBackLog
  },
  {
    path: '/requestExceptionLog',
    component: RequestExceptionLog
  },
  {
    path: '/billingStatistics',
    component: BillingStatistics
  }, {
    path: '/modelChargingMge',
    component: ModelChargingMge
  }, {
    path: '/testExamine',
    component: TestExamine
  }
  ]
}]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


// 解决重复点击相同路由报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}


//挂载路由导航守卫
router.beforeEach(async (to, from, next) => {
  // 登录页放行
  if (to.path === '/admin/login' || to.path === '/default' || to.path === '/stuweb') return next()


  // 应用页面，校验是否存在authtoken，如果没有则继续，有就直接放行
  let openUrl = ['/deepseekweb',
    '/aheadapp',
    '/deepseekweb',
    '/aheadweb',
    '/deepseekapp',
    '/audioToVideo',
    '/audioToVideoWeb',
    '/wanxiangNavigation',
    '/textToImage',
    '/imageToVideo',
    '/aaaa',
    '/qwqWeb',
    '/qwqApp']
  if (openUrl.includes(to.path)) {
    // 获取authtoken
    const authtoken = to.query.authToken;
    // 如果authtoken没有携带，查看缓存中是否存在
    if (!authtoken) {
      // 获取缓存内的authtoken
      const storgeAuthToken = window.sessionStorage.getItem('authtoken')
      if (storgeAuthToken) {
        // 存在，放行
        return next()
      } else {
        return next('/default')
      }
    } else {
      // authtoken存入缓存
      window.sessionStorage.setItem('authtoken', authtoken)
      return next()
    }
  }

  const tokenStr = window.sessionStorage.getItem('token')

  // token是否存在，不存在清除路由，以及跳转到登录页面
  if (!tokenStr) {
    store.commit('updateVerifyAuthStauts', false)

    for (const key in rightRoutes)
      router.addRoute({
        name: rightRoutes[key].name,
        path: `/${new Date()}`
      })

    return next('/default')
  }

  // token存在但是内存中登录状态为 false
  if (tokenStr && store.state.verifyAuthStauts === false) {
    // 获取登录状态
    await store.dispatch('verifyAuth')
    // 通过判断获取的登录状态来进行渲染路由或者删除路由
    if (store.state.verifyAuthStauts) {
      // 渲染路由，放行
      for (const key in rightRoutes) router.addRoute(rightRoutes[key])
      return next(to)
    } else {
      // 删除路由，跳转到登录页面
      for (const key in rightRoutes)
        router.addRoute({
          name: rightRoutes[key].name,
          path: `/${new Date()}`
        })
      console.error('非法跳转，请登录！')

      return next('/default')
    }
  } else if (tokenStr && store.state.verifyAuthStauts === true) {
    // 既有token，内存中登录状态为true，放行
    next()
  }

})

export default router