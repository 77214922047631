import axios from 'axios'

const baseURL = 'https://brain.jxuspt.com/aiplatform/'
// const baseURL = 'http://localhost:8557/'

const baseWS = 'wss://brain.jxuspt.com/aiplatform/ws'
// const baseWS = 'ws:/localhost:8557/ws'

const instance = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    let token = window.sessionStorage.getItem('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    if (
      error.code === 'ECONNABORTED' ||
      error.message.indexOf('timeout') !== -1
    ) {
      // 请求超时处理
      console.error('请求超时')
      return {
        code: 408,
        message: '请求超时',
      }
    } else if (
      error.code == 'ERR_NETWORK' ||
      error.message === 'Network Error'
    ) {
      // 网络错误处理
      console.error('网络错误')
      return {
        code: 404,
        message: '网络错误',
      }
    } else {
      // 其他错误处理
      console.error(error.code)
      return {
        code: error.code,
        message: '程序内部错误！',
      }
      // return Promise.reject(error)
    }
  }
)



const instanceWithoutToken = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

// 添加请求拦截器
instanceWithoutToken.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instanceWithoutToken.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    if (
      error.code === 'ECONNABORTED' ||
      error.message.indexOf('timeout') !== -1
    ) {
      // 请求超时处理
      console.error('请求超时')
      return {
        code: 408,
        message: '请求超时',
      }
    } else if (
      error.code == 'ERR_NETWORK' ||
      error.message === 'Network Error'
    ) {
      // 网络错误处理
      console.error('网络错误')
      return {
        code: 404,
        message: '网络错误',
      }
    } else {
      // 其他错误处理
      console.error(error.code)
      return {
        code: error.code,
        message: '程序内部错误！',
      }
      // return Promise.reject(error)
    }
  }
)



const instanceUrlencoded = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
})

// 添加请求拦截器
instanceUrlencoded.interceptors.request.use(
  function (config) {
    let token = window.sessionStorage.getItem('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instanceUrlencoded.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    if (
      error.code === 'ECONNABORTED' ||
      error.message.indexOf('timeout') !== -1
    ) {
      // 请求超时处理
      console.error('请求超时')
      return {
        code: 408,
        message: '请求超时',
      }
    } else if (
      error.code == 'ERR_NETWORK' ||
      error.message === 'Network Error'
    ) {
      // 网络错误处理
      console.error('网络错误')
      return {
        code: 404,
        message: '网络错误',
      }
    } else {
      // 其他错误处理
      console.error(error.code)
      return {
        code: error.code,
        message: '程序内部错误！',
      }
      // return Promise.reject(error)
    }
  }
)


const instanceFile = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

// 添加请求拦截器
instanceFile.interceptors.request.use(
  function (config) {
    let token = window.sessionStorage.getItem('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instanceFile.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    if (
      error.code === 'ECONNABORTED' ||
      error.message.indexOf('timeout') !== -1
    ) {
      // 请求超时处理
      console.error('请求超时')
      return {
        code: 408,
        message: '请求超时',
      }
    } else if (
      error.code == 'ERR_NETWORK' ||
      error.message === 'Network Error'
    ) {
      // 网络错误处理
      console.error('网络错误')
      return {
        code: 404,
        message: '网络错误',
      }
    } else {
      // 其他错误处理
      console.error(error.code)
      return {
        code: error.code,
        message: '程序内部错误！',
      }
      // return Promise.reject(error)
    }
  }
)


const instanceDownload = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
  responseType: 'blob'
})

// 添加请求拦截器
instanceDownload.interceptors.request.use(
  function (config) {
    let token = window.sessionStorage.getItem('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instanceDownload.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response
  },
  function (error) {
    // 对响应错误做点什么
    if (
      error.code === 'ECONNABORTED' ||
      error.message.indexOf('timeout') !== -1
    ) {
      // 请求超时处理
      console.error('请求超时')
      return {
        code: 408,
        message: '请求超时',
      }
    } else if (
      error.code == 'ERR_NETWORK' ||
      error.message === 'Network Error'
    ) {
      // 网络错误处理
      console.error('网络错误')
      return {
        code: 404,
        message: '网络错误',
      }
    } else {
      // 其他错误处理
      console.error(error.code)
      return {
        code: error.code,
        message: '程序内部错误！',
      }
      // return Promise.reject(error)
    }
  }
)

const instanceStream = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  responseType: 'stream', // 设置响应类型为流
});

// 添加请求拦截器
instanceStream.interceptors.request.use(
  function (config) {
    // 在请求发送之前做一些处理
    return config;
  },
  function (error) {
    // 对请求错误做一些处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instanceStream.interceptors.response.use(
  function (response) {
    // 将流式响应返回给调用方进行处理
    return response.data;
  },
  function (error) {
    if (
      error.code === 'ECONNABORTED' ||
      error.message.indexOf('timeout') !== -1
    ) {
      console.error('请求超时');
      return {
        code: 408,
        message: '请求超时',
      };
    } else if (
      error.code == 'ERR_NETWORK' ||
      error.message === 'Network Error'
    ) {
      console.error('网络错误');
      return {
        code: 404,
        message: '网络错误',
      };
    } else {
      console.error(error.code);
      return {
        code: error.code,
        message: '程序内部错误！',
      };
    }
  }
);

export { instance, instanceWithoutToken, instanceUrlencoded, instanceFile, instanceDownload, instanceStream, baseWS, baseURL }
