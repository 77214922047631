import Vue from 'vue'
import { 
    Form,
    Field,
    Button,
    Notify,
    ActionSheet, 
    Sticky,
    Picker,
    Image,
    Icon,
    Overlay,
    DatetimePicker,
    Popup,
    Toast,
    Empty,
    PullRefresh,
    Dialog,
    Divider,
    ShareSheet,
    SwipeCell,
    Switch,
    Badge,
    ImagePreview
} from 'vant';

Vue.use(Button)
Vue.use(Field)
Vue.use(Form)
Vue.use(ActionSheet)
Vue.use(Sticky)
Vue.use(Picker)
Vue.use(Icon)
Vue.use(Overlay)
Vue.use(DatetimePicker)
Vue.use(Popup) 
Vue.use(Empty)
Vue.use(Switch)
Vue.use(Image)
Vue.use(PullRefresh)
Vue.use(Dialog)
Vue.use(Divider)
Vue.use(SwipeCell)
Vue.use(ShareSheet)
Vue.use(Badge)

Vue.prototype.$toast = Toast
Vue.prototype.$notify = Notify
Vue.prototype.$dialog = Dialog
Vue.prototype.$imagePerview = ImagePreview